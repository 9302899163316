import React from 'react';
import { Row, Col } from 'antd';
import ProgressBar from '../../Progress';

const SkillsProgress = () => (
<div>

    <h2>Education</h2>
    <Row gutter={[30, 20]}>
    <ul>
    <Row gutter={[20, 20]}><li><h3>Hokkaido University (Apr. 2020 - )</h3>
    Bachelor in Computer Science and Information Technology</li></Row>
</ul>
</Row>

    <h2>Favorite Languages</h2>
    <Row gutter={[20, 20]}>
      <Col xs={24} sm={24} md={12}>

        <ProgressBar
          percent={80}
          text="Python"
        />
        <ProgressBar
          percent={70}
          text="JavaScript"
        />
        <ProgressBar
          percent={70}
          text="Go"
          
        />
        <ProgressBar
          percent={90}
          text="HTML,CSS"
        />
        <ProgressBar
          percent={50}
          text="C"
        />
        <ProgressBar
          percent={50}
          text="Rust"
        />
      </Col>
    </Row>
    
  </div>
);

export default SkillsProgress;
